import React from 'react'
import { graphql } from 'gatsby'
import { mapEdgesToNodes, filterOutDocsWithoutSlugs, filterOutHiddenDocs } from '../lib/helpers'
import CollaborationPreviewList from '../components/collaboration-preview-list'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/content'
import ListIntro from '../components/list-intro'
import ContentContainer from '../containers/content'

export const query = graphql`
  query CollaborationsIndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      openGraph {
        image {
          ...SanityImage
        }
      }
    }
    collaborationsIntro: sanityCollaborationsIntro(
      _id: { regex: "/(drafts.|)collaborationsIntro/" }
    ) {
      _rawIntroText(resolveReferences: { maxDepth: 5 })
    }
    collaborations: allSanityCollaboration(
      sort: { fields: [endDate], order: DESC }
      filter: { slug: { current: { ne: null } }, isVisible: { eq: true } }
    ) {
      edges {
        node {
          id
          title
          artist
          venue
          location
          startDate
          endDate
          previewImageSize {
            widthDesktop
          }
          mainImage {
            ...SanityImage
            alt
          }
          tags {
            _id
            title
          }
          slug {
            current
          }
          isVisible
          _rawFormattedTitle
        }
      }
    }
  }
`

const CollaborationsIndexPage = props => {
  const { data, errors } = props
  const collaborationsIntro = data.collaborationsIntro

  if (errors) {
    return (
      <ContentContainer>
        <GraphQLErrorList errors={errors} />
      </ContentContainer>
    )
  }

  const site = (data || {}).site
  const ogImage = site.openGraph.image
  const collaborationNodes = (data || {}).collaborations
    ? mapEdgesToNodes(data.collaborations).filter(filterOutDocsWithoutSlugs)
    : []

  if (!site) {
    console.warn(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <ContentContainer navMenuTitle="Collaborations">
      <SEO
        title="Balkan...Collaborations"
        description={site.description || ''}
        keywords={site.keywords || []}
        image={ogImage}
      />
      <div className="page-container is-collaborations-overview">
        <h1 hidden>{site.title}</h1>
        {collaborationsIntro && <ListIntro {...collaborationsIntro} />}
        <div>{collaborationNodes && <CollaborationPreviewList nodes={collaborationNodes} />}</div>
      </div>
    </ContentContainer>
  )
}

export default CollaborationsIndexPage
