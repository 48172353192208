import React from 'react'
import PortableText from './portableText'
import Ellipsis from '../images/icons/ellipsis.svg'

class ListIntro extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    const { _rawIntroText } = this.props
    return (
      _rawIntroText && (
        <div className="list-intro">
          <div className="list-intro__inner">
            {_rawIntroText && <PortableText blocks={_rawIntroText} />}
            {/* <div className="btn btn--ellipsis">
              <Ellipsis />
            </div> */}
          </div>
        </div>
      )
    )
  }
}

export default ListIntro
